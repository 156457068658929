<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <b>首页</b>
        </el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item>美食管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/merchant/list' }">店铺</el-breadcrumb-item>
        <el-breadcrumb-item>查看</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-form ref="form" :model="form" label-width="150px" class="el-form-self">
        
        <el-row>
          <el-col>
            <el-form-item label="名称:" prop="merchantName">
              <div class="view-text">{{form.merchantName}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="位置:" prop="merchantLocation">
              <div class="view-text">{{form.merchantLocation}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="电话:" prop="merchantPhone">
              <div class="view-text">{{form.merchantPhone}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <!--<el-row>-->
            <!--<el-col>-->
                <!--<el-form-item label="简介:" prop="merchantIntroduction">-->
                    <!--<div class="view-text">{{form.merchantIntroduction}}</div>-->
                <!--</el-form-item>-->
            <!--</el-col>-->
        <!--</el-row>-->

        <el-row>
          <el-col>
            <el-form-item label="推荐指数:" prop="merchantLevel">
              <div>{{ form.merchantLevel==='1'?'一星推荐': form.merchantLevel==='2'?'二星推荐': form.merchantLevel==='3'?'三星推荐': form.merchantLevel==='4'?'四星推荐': form.merchantLevel==='5'?'五星推荐':''}}</div>
              <template class="view-text" v-if="merchantLevel==='1'">一星推荐</template>
              <template  class="view-text" v-if="merchantLevel==='2'">二星推荐</template>
              <template class="view-text" v-if="merchantLevel==='3'">三星推荐</template>
              <template class="view-text" v-if="merchantLevel==='4'">四星推荐</template>
              <template class="view-text" v-if="merchantLevel==='5'">五星推荐</template>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="列表缩略图片:" prop="merchantPhoto" :disabled="true">
              <el-upload
                class="companyNewsImage-uploader"
                :action="uploadFileURL"
                :show-file-list="false"
              >
                <img v-if="merchantPhoto" :src="merchantPhoto" class="homeBannerImage" />
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="图片或者视频:" prop="videoName">
              <el-upload class="upload-demo" :action="uploadFileURL" :on-preview="handleImagePreview" :file-list="merchantVideoArray"></el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="内容:">
              <Editor id="tinymce" v-model="form.merchantContent" :init="init" :disabled="true"></Editor>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="置顶:" prop="merchantTop">
              <div class="view-text">{{form.merchantTop==0?'是':'否'}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="推荐:" prop="merchantRecommended">
              <div class="view-text">{{form.merchantRecommended==0?'是':'否'}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="阅读次数:" prop="merchantReadNumber">
              <div class="view-text">{{form.merchantReadNumber}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="点赞人数:" prop="star">
              <div class="view-text">{{ form.star || '0' }}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="状态:" prop="merchantStatus">
              <div class="view-text">{{form.merchantStatus==0?'已发布':'草稿'}}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="发布时间:" prop="formatMerchantCreateTime">
              <div class="view-text">{{form.formatMerchantCreateTime}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="上次修改时间:" prop="formatMerchantUpdateTime">
              <div class="view-text">{{ form.formatMerchantUpdateTime || '暂无更改' }}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="发布人:" prop="merchantPublisher" v-if="this.form.merchantStatus==0">
              <div class="view-text">{{form.merchantPublisher}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="保存人:" prop="merchantPublisher" v-if="this.form.merchantStatus==1">
              <div class="view-text">{{form.merchantPublisher}}</div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-buttons">
          <el-form-item class="el-form-item-self">
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-row>


        <el-dialog :visible.sync="merchantVideoDialogVisible" >
          <video v-if="uploadType==='video/mp4'" v-for="(video,index) in merchantVideoList" :key="index" width="100%" height="100%" :src="downloadFileURL+video+'/'" controls></video>
          <el-image v-if="uploadType!=='video/mp4'" v-for="(image,index) in merchantVideoList" :key="index" style="width: 200px; height: 200px" :src="downloadFileURL+image+'/'" alt="" :preview-src-list="merchantVideos"></el-image>
        </el-dialog>

      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import { uploadFileURL, downloadFileURL } from "../../../api/system-api";
import { reqGetMerchantOne } from "../../../api/merchant-api";
import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver/theme";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/icons/default/icons";
import "tinymce/plugins/code";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/importcss";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autosave";
import "tinymce/plugins/save";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/link";
import "tinymce/plugins/codesample";
import "tinymce/plugins/table";
import "tinymce/plugins/charmap";
import "tinymce/plugins/hr";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/anchor";
import "tinymce/plugins/toc";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/advlist";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/help";
import "tinymce/plugins/charmap";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/lists";
import 'tinymce/plugins/indent2em'
import "tinymce/icons/default/icons";
export default {
    components: {
        Editor,
    },
  data() {
    return {
      imageUrlDialogVisible: false,
      uploadFileURL: uploadFileURL,
      merchantPhoto: "",
      uploadType: "",
      downloadFileURL: downloadFileURL,
      editPath: "/merchant/list",
      defaultMsg: "",
      merchantLevel: "",
      form: {
        merchantName: "",
        merchantIntroduction:"",
        merchantLocation:"",
        merchantPhoto:"",
        merchantStatus: "",
        merchantPublisher: "",
        merchantLevel: "",
        merchantCreateTime: "",
        formatMerchantUpdateTime: "",
        formatMerchantCreateTime: "",
      },
        init: {
            language_url: "/static/tinymce/langs/zh_CN.js",
            language: "zh_CN",
            skin_url: "/static/tinymce/skins/ui/oxide",
            content_css: "/static/tinymce/skins/content/default/content.css",
            height: 400,
            font_formats:
                "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
            plugins:
                "code print preview indent2em importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image media link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars",
            toolbar:
                "undo redo |code bold italic underline strikethrough indent2em | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak charmap | fullscreen  preview print | image media  pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
        },
        merchantVideoArray:[],
        merchantVideoList:[],
        merchantVideoDialogVisible:false,
        merchantVideos:[]
    };
  },
  methods: {
      handleImagePreview(file) {
          var that=this;
          that.merchantVideos=[];
          that.merchantVideoList.forEach(function (obj) {
              that.merchantVideos.push(downloadFileURL+obj+'/')
          });
          that.merchantVideoDialogVisible=true;
      },
    reqGetMerchantOne(merchantId) {
      let that = this;
      reqGetMerchantOne({ merchantId: merchantId }).then((res) => {
        if (res.data.data.merchantPhoto) {
          this.merchantPhoto =
            downloadFileURL + res.data.data.merchantPhoto + "/";
        } else {
          this.merchantPhoto = "";
        }
        this.form = Object.assign({}, res.data.data);
        if (res.data.code === 1) {
          that.form = res.data.data;
            if(that.form.merchantVideo){
                that.merchantVideoList=that.form.merchantVideo.split(',');
                that.merchantVideoList.forEach(function (obj,i) {
                    that.merchantVideoArray.push({url:downloadFileURL+obj+'/',name:obj});
                    if(obj.toLowerCase().indexOf('.mp4')!==-1){
                        that.uploadType='video/mp4';
                    }
                })
            }
        } else {
          that.$message({
            message: "查询失败",
            type: "error",
          });
        }
      });
    },
  },
  mounted() {
    var row = this.$route.query;
    var merchantId = row.merchantId;
    this.reqGetMerchantOne(merchantId);
  },
};
</script>
